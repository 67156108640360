<template>
  <div :style="{ padding: '0 0 32px 32px' }">
    <h4 :style="{ marginBottom: '20px' }">{{ title }}</h4>
    <div id="statechart" class="chart" :key="Math.random()"></div>
  </div>
</template>

<script>
export default {
  name: 'Bar',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      echart_data: [],
    }
  },
  mounted() {
    this.loadECharts()
  },
  watch: {
    data: {
      immediate: true,
      handler(newValue) {
        this.echart_data = newValue
        this.$nextTick(() => {
          this.loadECharts()
        })
      },
    },
  },
  methods: {
    loadECharts: function () {
      const echart_title = []
      const echart_item = []
      if (this.echart_data.length > 0) {
        this.echart_data.forEach((element) => {
          echart_title.push(element.x)
          echart_item.push(element.y)
        })
      }
      let echarts = require('echarts')
      let myChart = echarts.init(document.getElementById('statechart'))
      myChart.setOption({
        xAxis: {
          show: true,
          name: '公司名称',
          type: 'category',
          data: echart_title,

          axisLabel: {
            show: true, // 是否显示刻度标签，默认显示
            interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；默认会采用标签不重叠的策略间隔显示标签；可以设置成0强制显示所有标签；如果设置为1，表示『隔一个标签显示一个标签』，如果值为2，表示隔两个标签显示一个标签，以此类推。
            formatter: function (value) {
              return value.split('').join('\n')
            },
          },
        },
        grid: {
          left: '10%',
          bottom: '45%',
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            showBackground: true,
            data: echart_item,
            type: 'bar',
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          },
        ],
      })
      //  自适应屏幕
      window.onresize = function () {
        myChart.resize()
      }
    },
  },
}
</script>
<style scoped>
.chart {
  width: 50vw;
  height: 60vh;
}
</style>
